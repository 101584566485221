import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import { FlattenedNodesObserver } from '@polymer/polymer/lib/utils/flattened-nodes-observer.js';
import { afterNextRender } from '@polymer/polymer/lib/utils/render-status.js';
import { uniqueId } from './util/uniqueId.js';
import { AiMap } from './util/aiMap.js';
import { dispatchEvent } from './util/eventsHelper.js';
import './ai-map-location.js';
/**
* `ai-google-map`
* 
* 
* @customElement
* @polymer
*/
class AiGoogleMap extends PolymerElement {
    static get is(){ return 'ai-google-map'; }
    static get template() {
        return html`
            <style>
                :host {
                    display: block;
                    width: var(--ai-google-map-width, 400px);
                    height: var(--ai-google-map-height, 300px);
                }
                .agm_map{
                    width:100%;
                    height:100%;
                }
            </style>
            <div class='agm_map'></div>
            <slot></slot>
		`;
	}
	static get properties() {
		return {
            version : {
                type : String,
                value : "0.4.1",
            },
			lat: {
				type: Number,
				value: null,
                reflectToAttribute : true
            },
            lng: {
                type: Number,
                value: null,
                reflectToAttribute : true
            },
            centerLat : {
                type : Number,
                reflectToAttribute : true
            },
            centerLng : {
                type : Number,
                reflectToAttribute : true
            },
            maxZoomOnLoad: {
                type: Number,
                value: 15
            },
            zoom:{
                type : Number,
                reflectToAttribute : true
            },
            iconUrl: {
                type : String,
                observer : "_iconObserver",
                reflectToAttribute : true
            },
            hoverIconUrl: {
                type : String,
                observer : "_iconObserver",
                reflectToAttribute : true
            },
            anchorX : {
                type : Number,
                reflectToAttribute : true
            },
            anchorY : {
                type : Number,
                reflectToAttribute : true
            },
            labelOriginX : {
                type : Number,
                value: 20,
                reflectToAttribute : true
            },
            labelOriginY : {
                type : Number,
                value: 20,
                reflectToAttribute : true
            },
            labelFontWeight : {
                type : String,
                value : "400",
                reflectToAttribute : true
            },
            labelColor : {
                type : String,
                value : "#000",
                reflectToAttribute : true
            },
            locationDefaults : {
                type : Object,
                computed: "_computeLocationDefaults(iconUrl, anchorX, anchorY, labelOriginX, labelOriginY, labelFontWeight, labelColor)"
            },
            mapLoaded : {
                type : Boolean,
                value : false,
                reflectToAttribute : true
            }         
		};
    }

    static get observers(){
        return [
            'updatePosition(centerLat,centerLng,zoom)'
        ];
    }

    constructor(){
        super();
        this._locations = [];
        this.locationsChangedHandler = this.locationsChangedHandler.bind(this);
        this._locationPropertyChangedHandler = this._locationPropertyChangedHandler.bind(this);
        this._trackedNodes = {};
        this._map = null;
        this._defaultZoom = 8;
        this._markerPromises = [];
    }


 
    connectedCallback(e){
        super.connectedCallback();
        //resize?
    }
    
    ready(){
        super.ready();
        afterNextRender(this, () => { //guarantee defer work until after paint
            
            this.addEventListener('locationchange', this.locationsChangedHandler);
            this.addEventListener('locationpropertychange', this._locationPropertyChangedHandler);
            //default constructor for single location
            //check to see if a default location was specified
            if(this.lat && this.lng){
                let {lat, lng} = this;
                this.addLocation({lat,lng});
            }
            //look for locations in the slotted elements
            let opts = Object.assign({
                fitBoundsByMarkers : this._applyAutoFit(),
                center: this._getInitialMapCenter(),
                zoom:this.zoom || this._defaultZoom,
                maxZoomOnLoad : this.maxZoomOnLoad
            }, this);
            
            this._map = new AiMap({
                element : this.shadowRoot.querySelector('.agm_map'),
                locations : this._locations,
                opts : opts 
            });
            const googleMapsPromise = scriptLoadHelper.requestNotify('googlemapsloaded');
            googleMapsPromise.then(() => {
                this.initMap();

                this._flattenedNodesObserver = new FlattenedNodesObserver(this, (info) => {
                    this._updateNodeTracking(info);
                });
            });

        });
    }

    

    /**
     * Initializes the Map (consumes command queue)
     */
    initMap(){
        return Promise.all(this._markerPromises.concat([this._map.initMap()]))
            .then(_ => {
                return new Promise(res => {
                    setTimeout(res, 0);
                });
            })
            .then(_ => {
                this.mapLoaded = true;
                dispatchEvent('aigooglemapload', this);
            });
        return;
    }

    _computeLocationDefaults(iconUrl, anchorX, anchorY, labelOriginX, labelOriginY, labelFontWeight, labelColor){
        let base = {};
        if(iconUrl){
            //conditionally add object based on assignable props
            base.icon = {
                url : iconUrl
            };
            if(labelOriginX !== undefined || labelOriginY !== undefined)
                base.icon.labelOrigin = {x : labelOriginX, y : labelOriginY};
            if(anchorX !== undefined || anchorY !== undefined)
                base.icon.anchor = {x : anchorX, y : anchorY};
        
        }
        if(labelFontWeight || labelColor){
            base.label = { text : " " };
            if(labelFontWeight)
                base.label.fontWeight = labelFontWeight;
            if(labelColor)
                base.label.color = labelColor;
        }
        return base;
    }

    _fillLocationDefaults(location){
        (function walk(location, defaults){
            for(var key in defaults){
                if(defaults[key] !== undefined){
                    if(location[key] === undefined){
                        location[key] = defaults[key];
                    }else if(typeof defaults[key] == 'object'){
                        walk.call(this, location[key], defaults[key]);//recur
                    }
                }
            }
        }).call(this, location, this.locationDefaults);
        return location;
    }

    // _fillLocationDefaults(location){
    //     console.log(location);
    //     let c = (function walk(location, defaults){
    //         let defaultsCopy = Object.assign({}, defaults);
    //         let locationCopy = Object.assign({}, location);
    //         console.log(defaultsCopy);
    //         console.log(locationCopy);
    //         for(var key in defaultsCopy){
    //             if(defaultsCopy[key] !== undefined){
    //                 //if(locationCopy[key] === undefined){
    //                     //locationCopy[key] = defaultsCopy[key];
    //                 //}else 
    //                 if(typeof defaultsCopy[key] == 'object'){
    //                     locationCopy[key] = walk.call(this, locationCopy[key], defaultsCopy[key]);//recur
    //                 }
    //             }
    //         }
    //         return Object.assign(defaultsCopy, locationCopy);
    //     }).call(this, location, this.locationDefaults);
    //     console.log(c);
    //     return c;
    //     return location;
    // }

    /**
     * Determines if the autofit heuristic is true/false
     */
    _applyAutoFit(){
        return ! (this.centerLat || this.centerLng || this.zoom);
    }

    //@todo locations isn't a populated array 
    get locations(){
        return this._locations.slice();//dup
    }
    set locations(data){
       this.setLocations(data);
    }
    
    _updateNodeTracking(info){
        if(info.addedNodes.length > 0){
            info.addedNodes.forEach((node) => {
                if(node.tagName === "AI-MAP-LOCATION"){
                    this._trackNode(node);
                }
            });
        }
        if(info.removedNodes.length > 0){
            info.removedNodes.forEach((node) => {
                if(node.tagName === "AI-MAP-LOCATION"){
                    //relocate
                    this._untrackNode(node);
                }
            });
        }
    }

    _trackNode(node){
        const uId = uniqueId('AiMapLocation');
        node.uId = uId;
        this._trackedNodes[uId] = node;
        // this.locations.push(node);
        this._bindTrackedNodeEvents(node);
        dispatchEvent('locationchange', this, {
            type:'LOCATION_ADDED',
            location: node
        });
    }

    _untrackNode(node){
        this._map.removeMarker(this._trackedNodes[node.uId].marker)
            .then(() => {
                delete this._trackedNodes[node.uId];
            });
        dispatchEvent('locationchange', this, {
            type:'LOCATION_REMOVED',
            location: node
        });
    }

    _bindTrackedNodeEvents(node){
        node.addEventListener('hoverstatechanged', this._renderMarker.bind(this, node));
    }

    _iconObserver(newVal, oldVal){
        Object.entries(this._trackedNodes).forEach(([key, node]) => {
            this._renderMarker(node);
        });
    }

    

    _getInitialMapCenter(){
        let latLng = {lat:0,lng:0};
        if(this.centerLat && this.centerLng){
            latLng = {lat : +this.centerLat, lng : +this.centerLng};
        }else{
            if(this.lat && this.lng){//check the lat and lng of the map
                latLng = {
                    lat : +this.lat,
                    lng : +this.lng
                }
            }else{//check the lat and lng of marked locations and use first
                for(let i in this._trackedNodes){
                    if(this._trackedNodes[i].lat && this._trackedNodes[i].lng){
                        latLng = {
                            lat : +this._trackedNodes[i].lat,
                            lng : +this._trackedNodes[i].lng
                        };
                        break;//just get the first
                    }
                }
            }
        }
      return latLng;
    };
    
    /**
     * event handler for the locationchange event
     * @param {CustomEvent} evt 
     * @todo expand with other event types? lat/lng update?
     */
    locationsChangedHandler(evt){
        if(evt.detail.type === "LOCATION_ADDED"){
            const location = evt.detail.location;
            var prom = this._map.addMarker(this._fillLocationDefaults(location))
                .then((marker) => {
                    this._trackedNodes[location.uId].marker = marker;
                });
            this._markerPromises.push(prom);    
        }
        if(this._applyAutoFit())
            this._map.boundMarkers(null, this.maxZoomOnLoad);
    }

    _propertyChangedNotify(oldVal, newVal){
        this._rerender();
    }

    _locationPropertyChangedHandler(event){
        this._rerender();
    }

    _rerender(){
        this.resetMarkerOpts();
        this._renderMarker(this._trackedNodes[event.detail.uId]);
    }
    

    resetMarkerOpts(){
        for(let [uId, node] of Object.entries(this._trackedNodes)){
            console.log(node);
            this._map.setMarkerOpts(node.marker, node);
        }
    }

    /**
     * 
     * @param {AiMapLocation} location map location object to render the marker
     */
    _renderMarker(location){
        let uId = location.uId;
            let offset = 1000;
            if(location.hovered){
                this._map.shiftMarkerZIndex(this._trackedNodes[uId].marker, offset);
            }else{
                this._map.shiftMarkerZIndex(this._trackedNodes[uId].marker, -1 * offset);
            }
            
            let nextIconUrl = 
                location.hovered 
                    ? location.hoverIconUrl
                        ? location.hoverIconUrl
                        : ( this.hoverIconUrl
                            ? this.hoverIconUrl
                            : ""
                        )
                    : (
                        location.iconUrl
                        ? location.iconUrl 
                        : ( this.iconUrl
                            ? this.iconUrl
                            : "" 
                        )
                    );
            if(nextIconUrl == "") return;
            
            this._map.changeMarkerIconUrl(
                this._trackedNodes[uId].marker, 
                nextIconUrl
            );
    }

    /**
     * Appends a location to the locations property
     * @param {*} locData object containing lat/lng
     */
   addLocation(locData){
       let location = document.createElement('ai-map-location');
       for(let [key, val] of Object.entries(locData)){
           location[key] = val;
       }
    //    location.lat = locData.lat;
    //    location.lng = locData.lng;
       this.appendChild(location);
       return;
    }

    /**
     * Updates the set of markers/nodes to reflect the provided set.
     * 
     * @param {Object[]} data Array of objects representing locations (lat, lng at a minimum)
     */
    setLocations(data){
        if(!(data instanceof(Array))) throw new Error("AiGoogleMaps.locations must be passed an Array of Objects");
        //get the locations which are persisting
        const prevLocations = Object.entries(this._trackedNodes)
            .filter(([key, loc]) => data.some(val => val.lat === loc.lat && val.lng === loc.lng))
            .map(([key, loc]) => key);
        Object.entries(this._trackedNodes).forEach(([key,loc]) => {
            //remove the nodes that aren't present in data
            if(! prevLocations.includes(key)){
                const node = this._trackedNodes[key];
                node.parentElement.removeChild(node);
                //cleanup will happen after this passes through the flattenedNodesObserver
            }
        });
        this._locations = prevLocations;//@todo remove locations?
        data.forEach((loc) => {
            if(! prevLocations.some(key => this._trackedNodes[key].lat === loc.lat && this._trackedNodes[key].lng === loc.lng)){
                this.addLocation(loc);
            }
        });
    }

    updatePosition(centerLat = 0,centerLng = 0,zoom = undefined){
        if(this.mapLoaded){
            zoom = zoom || this._defaultZoom;
            this._map.panZoom({lat:centerLat,lng:centerLng},zoom);
        }
    }
}

window.customElements.define(AiGoogleMap.is, AiGoogleMap);
